/* You can add global styles to this file, and also import other style files */
html {
  font-size: 16px;
  background-color: #fdfbfb;
  height: 100%;
}
body {
  font-family: 'Poppins', 'Lato', sans-serif;
  margin: 0;
  height: 100%;
}
#root {
  height: 100%;
}

.ql-editor::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  margin: 5px;
}

/* scrollbar track */
.ql-editor::-webkit-scrollbar-track {
  margin: 5px;
  background: #eee;
}
